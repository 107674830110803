* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 12pt;
}

li {
  list-style-type: none;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}
/* Uncomment and set these variables to customize the grid. */

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
:root {
  --color1: white;
  --color2: black;
  --color3: #035096;
  --color4: #888;
  --color5: #eee;
}

html {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 12pt;
  letter-spacing: 0.4pt;
  color: var(--color2);
}

body {
  display: flex;
  justify-content: center;
}

a {
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  color: var(--color3);
  text-decoration: underline;
}

br {
  clear: both;
  word-wrap: break-word;
}

button {
  border: 2px solid var(--color3);
  background-color: var(--color3);
  color: var(--color1);
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  border: 2px solid var(--color3);
  background-color: var(--color1);
  color: var(--color3);
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--color-1 {
  color: var(--color1);
}

.text--color-2 {
  color: var(--color2);
}

.text--color-3 {
  color: var(--color3);
}

.text--color-4 {
  color: var(--color4);
}

.text--size-xxs {
  font-size: 0.7rem;
}

.text--size-xs {
  font-size: 0.8rem;
}

.text--size-s {
  font-size: 0.9rem;
}

.text--size-m {
  font-size: 1rem;
}

.text--size-l {
  font-size: 1.1555rem;
}

.text--size-xl {
  font-size: 1.3555rem;
}

.text--size-xxl {
  font-size: 2.2rem;
}

.text--bold {
  font-weight: bold;
}

.text--up {
  text-transform: uppercase;
}

.text--bottom {
  vertical-align: bottom;
}

.text--nowrap {
  white-space: nowrap;
}

.padding-body {
  margin: 0;
  padding: 0;
  padding-top: 64px;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

#app {
  max-width: 1280px;
  overflow-x: hidden;
}

/* HACK */
.col-md-12 {
  padding: 0;
}

.signature {
  margin: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: var(--color3);
}

.signature__logo {
  height: 60px;
}

.signature__text {
}

@media only screen and (max-width: 48em) {
  html {
    font-size: 11pt;
    letter-spacing: 0.3pt;
  }
  .projects-list__item {
    margin-top: 8px;
  }
  .text--size-l {
    font-size: 1.0555rem;
  }
  .text--size-xl {
    font-size: 1.2555rem;
  }
  .text--size-xxl {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 68em) {
  .signature {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 75em) {
}

.menu {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--color1);

  display: flex;
  justify-content: center;
}

.menu-container {
  margin: 0;
  padding: 0;
  padding: 10px;
  height: 64px;
  width: 100%;
  max-width: 1280px;
  border-bottom: 2px solid var(--color3);
}

.menu__logo-d {
  height: 44px;
}

.menu__navigation {
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.menu__contacts {
  position: relative;
  top: -8px;
}

.navigation__toggle {
  display: none;
  width: 50px;
  height: 50px;
  font-size: 50px;
  padding: 0;
  float: right;
  background-color: var(--color1);
  border: none;
}

.navigation__toggle:hover {
  border: none;
}

.i-menu::before {
  content: "";
  display: block;
  background-image: url(.images/icons/menu-24px.svg);
  /* background-image: url(../../public/images/icons/menu-24px.svg); */
  background-size: cover;
  width: 46px;
  height: 46px;
  position: relative;
  top: -4px;
  left: -4px;
}

@media only screen and (max-width: 48em) {
  .menu__navigation {
    display: none;
  }

  .navigation__toggle {
    display: block;
  }

  .menu__contacts * {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 68em) {
}

@media only screen and (max-width: 75em) {
}

#about {
  visibility: hidden;
  height: 0;
  width: 0;
}

.about {
  margin: 0;
  margin-top: 64px;
  height: 400px;
  width: 100%;

  display: flex;
  align-items: center;
  overflow: hidden;

  background-color: var(--color4);
  background-image: url(./images/image_bg_1-min.png);
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-container {
  margin-left: 24px;
  border-radius: 5px;
  background-color: var(--color1);
  padding: 24px;
}

@media only screen and (max-width: 48em) {
  .about {
    background-color: var(--color1);
  }

  .about-container {
    margin: 0;
  }
}

@media only screen and (max-width: 68em) {
  .about {
    background-color: var(--color1);
  }
}

@media only screen and (max-width: 75em) {
}

.el-panel {
  width: 100%;
}

.panel-grid {
}

.panel-grid__row {
  margin-top: 64px;
  overflow: hidden;
}

.panel-grid__img {
  height: 160px;
  max-width: 100%;
  object-fit: cover;
}

.panel-grid__title {
}

.panel-grid__button {
  cursor: pointer;
  border-radius: 5px;
}

.panel-grid__button:hover {
  color: var(--color3);
}

.panel-grid__button::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 6px;
  width: 24px;
  height: 24px;
  background-image: url(./images/icons/keyboard_arrow_down-24px.svg);
}

.panel-list {
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
}

.panel-list__item {
  padding: 0;
  padding-bottom: 16px;
}

.offer {
}

.offer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

  border-radius: 5px;
  height: 240px;
  width: 100%;

  background-color: var(--color4);
  background-image: url(./images/image_bg_0-min.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 48em) {
}

@media only screen and (max-width: 68em) {
  .offer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .offer-container a {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 75em) {
}

.supplier {
}

.supplier__list {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}

.supplier__logo {
  height: 52px;
  width: auto;
  margin: 24px;
  margin-left: 0;
}

@media only screen and (max-width: 48em) {
  .supplier__logo {
    height: 28px;
    margin: 8px;
  }

  .supplier__list {
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 68em) {
}

@media only screen and (max-width: 75em) {
}

.projects {
}

.photo-container {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

.photo-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
  border-radius: 5px;
}

.photo-project {
  border-radius: 5px;
  margin-bottom: 16px;
  margin-top: 64px;

  position: absolute;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.figcaption {
  padding-top: 16px;
}

.projects__list {
  justify-content: space-around;
}

.projects-list__item {
  margin-top: 24px;
}

@media only screen and (max-width: 48em) {
  .projects-list__item {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 68em) {
  .projects-list__item {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 75em) {
}

.advantages {
  margin: 0;
  margin-top: 64px;
  padding-bottom: 48px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--color3);
  color: var(--color1);
}

.advantage-container {
  margin-top: 48px;
  display: flex;
  justify-content: space-around;
}

.advantage-title {
  font-size: 6.5rem;
  display: inline-block;
}

.advantage-postfix {
  position: relative;
  top: -4rem;
}

.advantage-caption {
}

@media only screen and (max-width: 48em) {
  .advantage-container {
    justify-content: start;
  }
}

@media only screen and (max-width: 68em) {
  .advantage-container {
    justify-content: center;
  }

  .advantage-postfix {
    display: none;
  }
}

@media only screen and (max-width: 75em) {
}

.contacts {
  padding: 0;
}

.contacts-container {
  margin-top: 24px;
}

.contact {
  display: flex;
  justify-content: start;
}

.contact-container {
  display: flex;
}

.contact__phone,
.contact__mail,
.contact__access-time,
.contact__address {
  margin-bottom: 24px;
}

.contact__mail {
  font-family: Arial, Helvetica, sans-serif;
}

.contact__addres {
  margin-top: 15px;
}

.icon {
  margin-right: 16px;
  margin-top: 8px;
}

.icon::before {
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: relative;
  top: 5px;
}

.menu__contacts .icon {
  margin-right: 8px;
  margin-top: -8px;
}

.menu__contacts .contact__phone,
.menu__contacts .contact__mail {
  margin-top: 8px;
  margin-bottom: 0;
}

.mail::before {
  background-image: url(./images/icons/mail_outline-24px.svg);
  /* background-image: url(../../public/images/icons/mail_outline-24px.svg); */
}

.phone::before {
  background-image: url(./images/icons/phone-24px.svg);
  /* background-image: url(../../public/images/icons/phone-24px.svg); */
}

.access-time::before {
  background-image: url(./images/icons/access_time-24px.svg);
  /* background-image: url(../../public/images/icons/access_time-24px.svg); */
}

.address::before {
  background-image: url(./images/icons/map-24px.svg);
  /* background-image: url(../../public/images/icons/map-24px.svg); */
  display: inline-block;
}

.map {
  margin: 0;
  padding: 0;
  margin-top: 64px;
  border-radius: 5px;
  overflow: hidden;
}
